<template>
  <v-container fluid class="pl-0 pr-0">
    <iframe :src="baseURL + '?user=' + currentUser.email+ '&name=' + encodeURI(currentUser.displayName)"   frameborder="0"></iframe>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

const baseURL = 'https://beereaders.retool.com/embedded/public/36591e15-26d3-40c1-bedc-b014359bba2e';

export default {
  name: 'Demos',
  computed: {
    ...mapState({
      currentUser: ({ currentUser }) => currentUser,
    }),
  },
  data: () => ({
    baseURL,
  }),
};
</script>
<style>
iframe{
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
